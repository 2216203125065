/* eslint-disable */
export const messages = {
  customer_welcome_news: "News",
  customer_welcome_news_title_08_04_2024: "New features of the GLOBAL SUITE",
  customer_welcome_news_content_08_04_2024: `GLOBAL CLIMATE is pleased to present the new module CSRD-Management: <ul class="ma-1"> <li>Step-by-step implementation of the double materiality analysis according to CSRD and ESRS</li> <li>Engagement with internal and external stakeholders</li> <li>Automated selection of disclosure requirements and data points based on the materiality analysis</li> <li>Preparation of the sustainability statement</li> <li>Auditable and transparent documentation of the entire process</li><li>Assignment of responsibilities and approval processes</li></ul>If you are also faced with the challenge of meeting your CSRD requirements, please do not hesitate to contact us.`,
  customer_welcome_news_title_22_09_2023: "New features of the GLOBAL SUITE",
  customer_welcome_news_content_22_09_2023: `GLOBAL CLIMATE is pleased to present the following new features of the GLOBAL SUITE: <ul class="ma-1"> <li>Introduction of an FAQ section</li> <li>New Landing Page, directly showing any open surveys</li> <li>Redesign of the supplier ESG Rating for better usability</li> <li>New, improved partner registration process</li> <li>Multiple smaller improvements and bugfixes in GLOBAL FOOTPRINT </li> </ul>`,
  customer_welcome_news_title_02_08_2023: "Improvements of the GLOBAL SUITE",
  customer_welcome_news_title_11_06_2023: "Improvement of the GLOBAL SUITE",
  customer_welcome_news_content_11_06_2023: `GLOBAL CLIMATE is pleased to announce the following improvements: <ul class="ma-1"> <li>Improved navigation through separation of individual products</li> <li>Combination of multiple commuter surveys to a single link</li> </ul>`,
  customer_welcome_news_title_18_05_2023: "Improvement of the GLOBAL SUITE",
  customer_welcome_news_content_18_05_2023: `GLOBAL CLIMATE is pleased to announce improvements for the following features: <ul class="ma-1"> <li>Improvement of Unit Booking excel upload</li> <li>Configuration of Origin and Destination for Logistic-Chains</li> </ul>`,
  customer_welcome_news_title_21_01_2023: "Improvements of the GLOBAL SUITE",
  customer_welcome_news_content_21_01_2023: `GLOBAL CLIMATE is pleased to announce that the following improvements have been released: <ul class="ma-1"> <li>New customisable GLOBAL FOOTPRINT Dashboard</li> <li>Beautification of the bookings excel reports</li> <li>Enable logging into multiple accounts with the same credentials</li> </ul> If you have any questions and if you need help to configure your new GLOBAL FOOTPRINT dashboard, please do not hesitate to contact us.`,
  customer_welcome_news_title_01_10_2022: "Improvements of the GLOBAL SUITE",
  customer_welcome_news_content_01_10_2022: `GLOBAL CLIMATE is pleased to present the new features of the GLOBAL SUITE. The following improvements have been implemented for GLOBAL FOOTPRINT: <ul class="ma-1"> <li>Improved filtering of Unit Bookings and export of data</li> <li>Versioning of customer specific CO<sub>2</sub> Database</li> </ul> We welcome suggestions and criticism, do not hesitate to contact us.`,
  customer_welcome_news_title_03_09_2022: "Improvements of the GLOBAL SUITE",
  customer_welcome_news_content_03_09_2022: `GLOBAL CLIMATE is pleased to present the next version of the GLOBAL SUITE. <br> The new version includes the following improvements: <ul class="ma-1"> <li> Revised, faster navigation <ul> <li>All products are now available with one click</li> <li>Many detail pages have been replaced by dialogs</li> <li>Unification of the styling</li> </ul> </li> <li>Faster application loading times</li> </ul> We welcome suggestions and criticism, do not hesitate to contact us.`,
  customer_welcome_news_title_17_06_2022: "Extension of the GLOBAL CLIMATE Automation API",
  customer_welcome_news_content_17_06_2022: `GLOBAL CLIMATE is pleased to announce, that the functionality of the Automation API has been extended. <br> The following improvements have been implemented for the GLOBAL FOOTPRINT Unit bookings: <ul class="ma-1"> <li>Return of the CO<sub>2</sub> emissions split over the scopes on the creation of a booking</li> <li>Batch support when querying unit bookings</li> <li>Batch support when canceling unit bookings</li> </ul> If you have any questions about the Automation API, please do not hesitate to contact us.`,
  customer_welcome_benefit_when_using_sco2pes: "GLOBAL FOOTPRINT CO<sub>2</sub> Accounting",
  customer_welcome_sco2pes_benefit_1:
    "Process integrated and financial data based CO<sub>2</sub> accounting according to the GHG protocol",
  customer_welcome_sco2pes_benefit_2: "Use of real values from your company instead of estimates and statistics",
  customer_welcome_sco2pes_benefit_3: "Real-time results",
  customer_welcome_sco2pes_benefit_4: "Complete CO<sub>2</sub> balance (Scope 1, Scope 2 and Scope 3)",
  customer_welcome_sco2pes_benefit_5: "Scientifically verified CO<sub>2</sub> database",
  customer_welcome_sco2pes_benefit_6: "Customized accounting of CO<sub>2</sub> relevant business transactions",
  customer_welcome_sco2pes_benefit_7: "Interface to SAP, Microsoft Dynamics and DATEV",
  customer_welcome_dialog_title: "Welcome on the GLOBAL SUITE",
  customer_welcome_dialog_description:
    "You successfully registered on the GLOBAL SUITE.<br />Feel free to look around the platform. You can find your open surveys in the navigation menu under Surveys.",
  customer_welcome_news_update: "New Update",
  customer_welcome_banner_title_1: "Welcome to the",
  customer_welcome_banner_title_2: "GLOBAL SUITE",
  customer_welcome_banner_title_3:
    "The all-in-one software solution for <b>efficient, compliant and audit-proof</b> sustainability reporting",
  customer_welcome_survey_title_1: "You have survey to complete",
  customer_welcome_survey_subtitle_plural: "You have <strong>{0}</strong> surveys open",
  customer_welcome_survey_subtitle_singular: "You have <strong>{0}</strong> survey open",
  customer_welcome_update_title: "The new update is released",
  customer_welcome_update_subtitle: "Improvements of the GLOBAL SUITE",
  customer_welcome_product_title: "Our Solution",
  customer_welcome_product_subtitle:
    "Our software fully complies with the regulatory ESG requirements of CSRD and LkSG and brings a maximum of efficiency in your CO<sub>2</sub>-Accounting and -Reporting.",
  customer_welcome_product_scopes_description:
    "Fully automated CO<sub>2</sub>-Accounting in Scope 1, 2, 3 and calculation of Product Carbon Footprints along the entire product life cycle.",
  customer_welcome_product_scores_description:
    "Fulfill statutory reporting obligations completely, legally compliant and audit-proof.",
  customer_welcome_ccf_product_link: "CCF-Tool",
  customer_welcome_pcf_product_link: "PCF-Tool",
  customer_welcome_sbt_product_link: "SBT-Tool",
  customer_welcome_esg_product_link: "CSRD-Tool",
  customer_welcome_lksg_product_link: "LkSG-Tool",
  customer_welcome_csrd_product_link: "ESG-Tool",
  customer_welcome_sales_title: "Contact sales team for more information",
  customer_welcome_sales_subtitle:
    "We look forward to hearing from you. Just contact us by phone, email or contact form or book a demo directly. We are ready! ",
  customer_welcome_sales_cta_1: "Contact sales",
  customer_welcome_sales_cta_2: "Book a demo now",
  customer_welcome_sales_dialog_title: "We look forward to hearing from you",
  customer_welcome_sales_dialog_description:
    "Feel free to give us a call or contact us via email. We are here and look forward to a first meeting to get to know you!",
  customer_welcome_sales_support_ticket: "Support ticket",
};
