/* eslint-disable */
export const messages = {
  customer_support_support_tickets: "Support Tickets",
  customer_support_new_ticket_title: "Neues Support Ticket",
  customer_support_no_tickets_created: "Keine Support Tickets erstellt",
  customer_support_search_by_search_term: "Suche nach Ticketnummer",
  customer_support_ticket_number: "Ticketnummer",
  customer_support_subject: "Betreff",
  customer_support_message: "Geben Sie Ihre Nachricht ein",
  customer_support_user: "Benutzer",
  customer_support_company: "Unternehmen",
  customer_support_selected_file_size_must_be_less_than_1mb: "Die Datei darf maximal 1 MB groß sein",
  customer_support_selected_file_size_must_be_less_than_5mb: "Die ausgewählte Dateigröße muss weniger als 5 MB betragen",
  customer_support_ticket_successfully_created: "Ticket erfolgreich erstellt",
  customer_support_ticket_successfully_closed: "Ticket erfolgreich geschlossen",
  customer_support_ticket_state_successfully_updated: "Ticketstatus erfolgreich aktualisiert",
  customer_support_create_ticket_dialog_title: "Support Ticket erstellen",
  customer_support_new_ticket_description: "Wenn Sie uns Fragen oder Anregungen senden möchten, füllen Sie das Formular aus. Unser Support-Team wird Ihnen so schnell wie möglich beantworten und Sie per E-Mail informieren.",
  customer_support_create_ticket_modal_text: "Unser Support-Team wird Ihnen so schnell wie möglich antworten. Wir werden Sie per E-Mail informieren, wenn Ihr Ticket beantwortet wurde.",
  customer_support_do_you_really_want_to_close_the_support_ticket: "Möchten Sie das Support-Ticket wirklich schließen?",
  customer_support_attachment_file: "Angehängte Datei",
  customer_support_closed_the_ticket_on: "schloss das Ticket am",
  customer_support_send_message: "Nachricht senden",
  customer_support_close_ticket: "Ticket schließen",
  customer_support_show_all: "Alle Tickets",
  customer_support_waiting_for_support: "Warten auf Unterstützung",
  customer_support_waiting_for_customer: "Warten auf Kunde",
  customer_support_closed: "Geschlossen",
  customer_support_info: "Bei eventuellen Rückfragen können Sie jederzeit ein Support-Ticket einreichen. Wir bearbeiten dieses gerne während unserer Öffnungszeiten von Montag - Freitag zwischen 09:00 Uhr und 17:00 Uhr für Sie.",
  customer_support_close_ticket_description: "Wenn Sie keine weiteren Anfragen haben, können Sie das Ticket schließen.",
  customer_support_send_only: "Nur senden",
  customer_support_send_only_desc: "Nachricht senden, ohne den Status zu ändern",
  customer_support_send_and_update: "Senden und Status aktualisieren",
  customer_support_send_and_update_desc: "Nachricht senden und automatisch den Status auf \"Warten auf Kunde\" aktualisieren",
  customer_support_send_and_close: "Senden und Ticket schließen",
  customer_support_send_and_close_desc: "Nachricht senden und Ticket schließen",
  customer_support_close_ticket_desc: "Ticket ohne Nachricht an den Kunden schließen",
};
