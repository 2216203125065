import { SelectedCompanyMethod } from "../..";

export class CustomerSco2pesURLs {
  constructor(private readonly customerHome: SelectedCompanyMethod) {}

  home() {
    return `${this.customerHome()}/sco2pes`;
  }

  info() {
    return `${this.home()}/info`;
  }

  financialBookings() {
    return `${this.home()}/bookings/financial-bookings`;
  }

  pendingFinancialBookings() {
    return `${this.home()}/bookings/pending-financial-bookings`;
  }

  unitBookings() {
    return `${this.home()}/bookings/unit-bookings`;
  }

  pendingUnitBookings() {
    return `${this.home()}/bookings/pending-unit-bookings`;
  }

  co2Dashboard() {
    return `${this.home()}/dashboard`;
  }

  suppliers() {
    return `${this.home()}/suppliers`;
  }

  financialModels() {
    return `${this.home()}/financial-models`;
  }

  supplierDetails(id: string) {
    return `${this.home()}/suppliers/${id}`;
  }

  transportRouteDetails(supplierId: string, transportRouteId: string) {
    return `${this.home()}/suppliers/${supplierId}?transportRouteId=${transportRouteId}`;
  }

  co2Database() {
    return `${this.home()}/co2-database`;
  }

  co2DatabaseDetails(id: string) {
    return `${this.home()}/co2-database?id=${id}`;
  }

  commuterSurveys() {
    return `${this.home()}/commuter-surveys`;
  }

  commuterSurveyDetails(id: string) {
    return `${this.home()}/commuter-surveys/${id}`;
  }

  companyCodes() {
    return `${this.home()}/company-codes`;
  }

  companyGroup() {
    return `${this.home()}/company-group`;
  }

  scenarioCalculator() {
    return `${this.home()}/scenario-calculator`;
  }

  checklist() {
    return `${this.home()}/checklist`;
  }

  integrationCo2TypeTags() {
    return `${this.home()}/integration/co2-type-tags`;
  }

  integrationConfiguration() {
    return `${this.home()}/integration/configuration`;
  }
}
