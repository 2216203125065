export enum LicenseType {
  ESG_FREE_LICENSE = "ESG_FREE_LICENSE",
  ESG_SMALL_LICENSE = "ESG_SMALL_LICENSE",
  ESG_MEDIUM_LICENSE = "ESG_MEDIUM_LICENSE",
  ESG_LARGE_LICENSE = "ESG_LARGE_LICENSE",
  LKSG_LICENSE = "LKSG_LICENSE",
  CSRD_LICENSE = "CSRD_LICENSE",
  CCF_LICENSE = "CCF_LICENSE",
}

export interface License {
  type: LicenseType;
  validUntil: number;
}
