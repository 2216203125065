import { isEmpty, isNil } from "lodash";

export class StringHelper {
  static instance: StringHelper = new StringHelper();

  private static UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  isUuid(str: string): boolean {
    return StringHelper.UUID_REGEX.test(str);
  }

  removePrefix(str: string, prefix: string): string {
    if (isEmpty(str) || isEmpty(prefix) || prefix.length > str.length) {
      return str;
    }
    return str.startsWith(prefix) ? str.substr(prefix.length) : str;
  }

  ensurePrefix(str: string, prefix: string): string {
    return `${prefix}${this.removePrefix(str, prefix)}`;
  }

  removeSuffix(str: string, suffix: string): string {
    if (isEmpty(str) || isEmpty(suffix) || suffix.length > str.length) {
      return str;
    }
    return str.endsWith(suffix) ? str.substr(0, str.length - suffix.length) : str;
  }

  ensureSuffix(str: string, suffix: string): string {
    return `${this.removeSuffix(str, suffix)}${suffix}`;
  }

  splitByN(str: string, N: number): RegExpMatchArray | null {
    return str.match(new RegExp(`.{${N}}`, "g"));
  }

  replaceAll(str: string, find: string, replace: string): string {
    function escapeRegExp(string: string): string {
      return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
    }

    return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
  }

  parseNumber(str: string, defaultValue: number, writeWarn?: boolean): number {
    try {
      return parseInt(str);
    } catch (error) {
      if (writeWarn) {
        console.warn(error);
      }
      return defaultValue;
    }
  }

  parseBoolean(str: string | null | undefined, defaultValue: boolean, writeWarn?: boolean): boolean {
    try {
      const v = str;
      if (isNil(v)) {
        return defaultValue;
      }
      return v.toLowerCase() === "true";
    } catch (error) {
      if (writeWarn) {
        console.warn(error);
      }
      return defaultValue;
    }
  }

  shorten(str: string, length: number) {
    if (str === undefined || str === null) {
      return "";
    }
    if (str.length < length) {
      return str;
    }
    return str.substr(0, length) + " ...";
  }
}
